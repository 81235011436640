// import { createStore, applyMiddleware, compose } from "redux";
// import { persistStore, persistReducer } from 'redux-persist';
// import storage from 'redux-persist/lib/storage'; 
// import thunk from "redux-thunk";
// import rootReducer from "./reducers";

// const initialState = {};
// const middleware = [thunk];

// const persistConfig = {
//   key: 'root', // key is required
//   storage, // the storage engine to use
//   // Optionally, you can whitelist specific reducers to be persisted:
//   // whitelist: ['auth'],
// };
// const persistedReducer = persistReducer(persistConfig, rootReducer);
// let store;
// if (["production", "demo"].includes(process.env.NODE_ENV)) {
//     store = createStore(
//         persistedReducer,
//         initialState,
//         compose(
//             applyMiddleware(...middleware)
//         )
//     );
// } else {
//     store = createStore(
//         persistedReducer,
//         initialState,
//         compose(
//             applyMiddleware(...middleware),
//             window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
//         )
//     );
// }

// export const persistor = persistStore(store);
// export default store;

import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import rootReducer from "./reducers";

const initialState = {};
const middleware = [thunk];

let store;
if (["production", "demo"].includes(process.env.NODE_ENV)) {
    store = createStore(
        rootReducer,
        initialState,
        compose(applyMiddleware(...middleware))
    );
} else {
    store = createStore(
        rootReducer,
        initialState,
        compose(
            applyMiddleware(...middleware),
            window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
        )
    );
}

export default store;

import React, { useState, useEffect } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useSelector } from 'react-redux';

// import lib
import isLogin from '../../lib/isLogin';
import isEmpty from "../../lib/isEmpty";

const ConditionRoute = ({ type, children, ...rest }) => {
   const siteDoc = useSelector((state) => state.siteSetting);
   const currentLocation = useLocation();
   const [restrictData, setResData] = useState(true);

   useEffect(() => {
      setResData(true);

      if (!isEmpty(siteDoc)) {
         if (currentLocation.pathname.startsWith("/trade") && !siteDoc.tradeAccess) {
            setResData(false);
         } else if (currentLocation.pathname.startsWith("/staking") && !siteDoc.stakeAccess) {
            setResData(false);
         } else if (currentLocation.pathname.startsWith("/wallet") && !siteDoc.walletAccess) {
            setResData(false);
         } else if (currentLocation.pathname.startsWith("/transaction-history") && !siteDoc.transactionAccess) {
            setResData(false);
         } else if (currentLocation.pathname.startsWith("/hedging") && !siteDoc.hedgeAccess) {
            setResData(false);
         } else if (currentLocation.pathname.startsWith("/stakinghistory") && !siteDoc.historyAccess) {
            setResData(false);
         } else if (currentLocation.pathname.startsWith("/live-events") && !siteDoc.liveEventAccess) {
            setResData(false);
         }
      }
   }, [siteDoc, currentLocation]);

   // Handle redirects based on conditions
   if (type === 'auth' && isLogin()) {
      return <Navigate to='/' />;
   } else if (type === 'private' && !isLogin()) {
      return <Navigate to='/login' />;
   } else if (!restrictData) {
      return <Navigate to='/' />;
   }

   return (
      <>
         {children}
      </>
   );
};

export default ConditionRoute;

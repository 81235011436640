import React, { useState, useEffect } from 'react';
import ReactApexChart from "react-apexcharts";
import moment from 'moment';
import { getHedgingList } from '../../api/tradeAction.js';
import { toFixed } from '../../lib/roundOf.js';

export default function PortfolioPerformance() {
  const [chartdata, setchartdata] = useState([]);
  const [chartDate, setchartDate] = useState([]);
  const [filterType, setFilterType] = useState('day'); // Initial filter type

  const chartOptions = {
    chart: {
      foreColor:  "#000",
      toolbar: {
        show: false,
      },
      height: 300,
      type: "area",
      id: "area-chart",
      zoom: {
        enabled: false,
      },
      dropShadow: {
        enabled: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      width: 1,
    },
    tooltip: {
      theme: "light",
    },
    grid: {
      borderColor: "transparent",
      row: {
        colors: ["transparent", "transparent"],
        opacity: 0.5,
      },
    },
    xaxis: {
      type: "category",
      categories: chartDate,
      labels: {
        formatter: function (val) {
          // console.log(val,"---------val")
          return val
        }
      }
    },
    yaxis: {
      labels: {
        formatter: function (value) {
          return toFixed(value, 4); // Formats to 4 decimal places
        },
      },
    },
  };

  const fetchData = async () => {
    try {
      let data = {
        filter: filterType,
      };
      const { status, result } = await getHedgingList(data);

      if (status === "success") {
        let seriesData = {};
        let dateArray = [];

        result.forEach(item => {
          console.log(item, "------item")
          const currency = item.firstCurrency;
          if (!seriesData[currency]) {
            seriesData[currency] = {
              name: currency,
              data: [],
            };
          }
          seriesData[currency].data.push(item.total);
          if (filterType == 'year') {
            const monthNames = moment().month(item.month - 1).format('MMMM')
            dateArray = [...new Set([...dateArray, monthNames])]
          } else if (filterType == 'month' || filterType == 'week') {
            const date = moment(item.day, "D").format("MMM D")
            dateArray = [...new Set([...dateArray, date])]
          } else {
            const date = moment().date(item.hour + 1).month(6).format('MMM-DD HH')
            dateArray = [...new Set([...dateArray, date])]

          }
        });
        const dataArray = Object.values(seriesData);
        setchartdata(dataArray);
        setchartDate(dateArray);
      }
    } catch (err) {
      console.log(err, "error");
    }
  };

  useEffect(() => {
    fetchData(); // Fetch data initially based on initial filterType
  }, [filterType]); // Fetch data whenever filterType changes

  const handleFilterChange = (filter) => {
    setFilterType(filter);
  };


  return (
    <div className="traffic_chart_flex">
      <div className="filter-buttons chart-group-button">
        <button className={filterType == 'day' ? "primary_btn me-2" : "border_btn me-2"} onClick={() => handleFilterChange('day')}>Day</button>
        <button className={filterType == 'week' ? "primary_btn me-2" : "border_btn me-2"} onClick={() => handleFilterChange('week')}>Week</button>
        <button className={filterType == 'month' ? "primary_btn me-2" : "border_btn me-2"} onClick={() => handleFilterChange('month')}>Month</button>
        <button className={filterType == 'year' ? "primary_btn me-2" : "border_btn me-2"} onClick={() => handleFilterChange('year')}>Year</button>
      </div>
      <ReactApexChart
        options={chartOptions}
        series={chartdata}
        type="area"
        height={500}
      />
    </div>
  );
}

// import lib
import isEmpty from '../../lib/isEmpty';


export const OrderValidate = (value) => {
    let errors = {};

    if (isEmpty(value.quantity)) {
        errors.quantity = "Quantity field is Required"
        return errors;
    } else if (isNaN(value.quantity)) {
        errors.quantity = "Quantity only numeric value"
        return errors;
    } else if (parseFloat(value.quantity) <= 0) {
        errors.quantity = "Quantity must be greater than zero"
        return errors;
    }

    if (isEmpty(value.buyorsell)) {
        errors.buyorsell = "Side field is Required"
        return errors;
    } else if (!['buy', 'sell'].includes(value.buyorsell)) {
        errors.value = "Invalid side"
        return errors;
    }

    if (isEmpty(value.pairId)) {
        errors.pairId = "Pair field is Required"
        return errors;
    }
}
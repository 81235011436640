// import config
import axios, { handleResp } from '../config/axios'


export const getBlogList = async (data) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `/api/user/getBlogList`,
            data: data
        });

        return {
            status: "success",
            loading: false,
            result: respData.data.result
        }
    }
    catch (err) {
        handleResp(err, 'error')
        return {
            status: "failed",
            loading: false,
        }
    }
}

import React, { useEffect, useState, useContext } from "react";
import CurrencyFormat from 'react-currency-format';
import "rc-slider/assets/index.css";




//import api
import {
  getStackingEstimate
} from "../../api/staking";


// lib
import { toFixedDown, truncateDecimals } from "../../lib/roundOf";


const StackedDetail = () => {

  //state
  const [tradedata, setTradeData] = useState([])


  const getStackinginfo = async (spotPairId, type) => {
    try {
      const { status, result } = await getStackingEstimate(spotPairId);
      if (status == "success") {
        setTradeData(result)
      } else {
      }
    } catch (err) {
      console.log(err, 'errro');
    }
  };


  useEffect(() => {
    getStackinginfo()
  }, [])

  return (
    <div className="dash_wrapper">
      <div className="row copy_trade_row">
        <div className="col-lg-12">
          <div className="dash_box trade_top_info">
            <div style={{ flex: 1, justifyContent: "center", alignItems: "center" }}>
              <label>Total Earned Redemption</label>
              <label >$ <CurrencyFormat displayType={'text'} thousandSeparator={true} value={truncateDecimals(tradedata?.redemption, 2)} /></label>
            </div>
            <div style={{ flex: 1, justifyContent: "center", alignItems: "center" }}>
              <label>Interest Paid</label>
              <label>$ <CurrencyFormat displayType={'text'} thousandSeparator={true} value={truncateDecimals(tradedata?.interest, 2)} /></label>
            </div>
            <div style={{ flex: 1, justifyContent: "center", alignItems: "center" }}>
              <label>Estimated APY Interest</label>
              <label>$ <CurrencyFormat displayType={'text'} thousandSeparator={true} value={truncateDecimals(tradedata?.futureInterestYearly, 2)} /></label>
            </div>
            <div style={{ flex: 1, justifyContent: "center", alignItems: "center" }}>
              <label>Estimated Total Interest</label>
              <label>$ <CurrencyFormat displayType={'text'} thousandSeparator={true} value={truncateDecimals(tradedata?.furueRedemption, 2)} /></label>
            </div>
            <div style={{ flex: 1, justifyContent: "center", alignItems: "center" }}>
              <label>Estimated Total Redemption</label>
              <label>$ <CurrencyFormat displayType={'text'} thousandSeparator={true} value={truncateDecimals(tradedata?.futureAmount, 2)} /></label>
            </div>
          </div>
        </div>


      </div>

    </div >
  );
};

export default StackedDetail;

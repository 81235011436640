import isEmpty from "../../lib/isEmpty";

export const StakingValidation = value => {
    let errors = {};

    // if (isEmpty(value.receiverAddress)) {
    //     errors.receiverAddress = "Receiver address field is required"
    // }

    if (isEmpty(value.price)) {
        errors.price = "Amount field is required"
    } else if (isNaN(value.price)) {
        errors.price = "Only allowed numeric"
    }

    // if (value.type === 'fixed') {

    // }

    return errors;
}
// import constant
import {
    SET_USER_WALLET_LIST
} from '../constant';

const initialValue = []

const wallet = (state = initialValue, action) => {
    switch (action.type) {
        case SET_USER_WALLET_LIST: return action.data
        default:
            return state;
    }
}

export default wallet;
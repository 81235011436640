import React, { useEffect } from "react";
import Footer from "../components/Footer.js";
import NavbarInner from "../components/Navbar-Inner.js";
import NavbarFixed from "../components/Navbar-Fixed.js";

// Images
import copy_icon from "../assets/images/copy_icon.svg";
import { Link, useNavigate, useParams } from "react-router-dom";

// import components
import Withdraw from '../components/Withdraw';
import isEmpty from "../lib/isEmpty.js";

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function WithdrawPage(props) {
  const { coin } = useParams();
  const history = useNavigate();
  useEffect(() => {
    if (isEmpty(coin)) {
      // history.push("/wallet");
    }
  }, [coin]);

  console.log(coin, 'coincoin')
  return (
    <div>
      <ScrollToTopOnMount />
      <NavbarInner />
      <div className="innerpages_wrapper">
        <div className="container">
          {/* <div className="inner_title_wrapper inner_title_wrapper_flex">
            <Link to="/wallet">
              <img
                alt="Nav"
                className="img-fluid"
                src={require("../assets/images/nav_arrow.png")}
              />
            </Link>
            <h2 className="inner_title">Withdraw</h2>
          </div> */}
          <Withdraw coin={coin} />
        </div>
      </div>
      <Footer />
      {/* <NavbarFixed /> */}
    </div>
  );
}

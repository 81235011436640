import { combineReducers } from "redux";
import authReducer from "./authReducer";
// import errorReducer from "./errorReducers";

// common
// import modal from './modal';
// import form from './form';


import account from './account';
// import bankDetail from './bankDetail';
// import userSetting from './userSetting';
// import language from './language';
import currency from './currency';
// import timezone from './timezone';
// import userKyc from './userKyc';
import wallet from './wallet';
import pairList from './pairListReducer';
import tradePair from './tradePairReducers'
import marketPrice from './marketPriceReducers';
// import themeReducers from './themeReducers';
// import tradeTheme from './tradeTheme';
// import orderBookDetail from './orderBookDetail'
// import stakeOrder from './stakeOrderReducer';
import priceConversion from './priceConversion';
// import instantPair from './instantPair';
// import announcement from './announcement';
// import p2pPost from './p2pPost';
// import p2pOrder from './p2pOrder';
// import p2pPair from './p2pPair';
// import iconBtn from './iconBtn';    
import notice from './notice';
// import referralLevel from './referralLevel';
// import referralSettings from './referralSettings';
// import purchaseToken from './purchaseToken';
// import upiDetail from './upiDetail';
// import qrCodeDetail from "./qrCodeDetail";
// import tradeReducers from './tradeReducers'
import tradeAsset from './tradeAsset'
import availableBal from "./availableBalance"
import siteSetting from "./sitesetting"

export default combineReducers({
    auth: authReducer,
    account,
    siteSetting,
    // bankDetail,
    // upiDetail,
    // qrCodeDetail,
    // userSetting,
    // language,
    currency,
    // timezone,
    availableBal,
    // userKyc,
    wallet,
    // referralLevel,
    // referralSettings,
    pairList,
    tradeAsset,
    tradePair,
    marketPrice,
    // theme: themeReducers,
    // tradeTheme,
    // orderBookDetail,
    // stakeOrder,
    priceConversion,
    // instantPair,
    // announcement,
    // p2pPost,
    // p2pOrder,
    // p2pPair,
    // iconBtn,
    notice,
    // purchaseToken
    // modal: modal,
    // form,
    // trade: tradeReducers,
    // errors: errorReducer
});
import React, { useEffect } from "react";
import Footer from "../components/Footer.js";
import NavbarInner from "../components/Navbar-Inner";
import NavbarFixed from "../components/Navbar-Fixed.js";
import Wallet from "../components/WalletNew";

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function WalletPage() {
  return (
    <div>
      <ScrollToTopOnMount />
      <NavbarInner />
      <Wallet />
      <Footer />
      {/* <NavbarFixed /> */}
    </div>
  );
}

import React, { useEffect } from "react";
import Footer from "../components/Footer.js";
import NavbarInner from "../components/Navbar-Inner";
import BlogList from "../components/Blog/list.js";


// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function Live_Events() {

  return (
    <div>
      <ScrollToTopOnMount />
      <NavbarInner />
      <div className="innerpages_wrapper">
        <div className="container-fluid">
          {/* <div className="inner_title_wrapper">
            <h2 className="inner_title">Staking</h2>
          </div> */}
          <div className="dash_wrapper">
            <BlogList />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import CurrencyFormat from 'react-currency-format';

// import api
import { getTrnxHistory } from "../../api/walletAction";

// import lib
import { dateTimeFormat } from "../../lib/dateTimeHelper";
import { toFixedDown, truncateDecimals } from "../../lib/roundOf"
import isEmpty from "../../lib/isEmpty";

// Latest Transaction Table Data
const columns = [
  {
    name: "Date",
    width: "180px",
    cell: (record) => `${dateTimeFormat(record?.createdAt)}`,
  },
  {
    name: "Coin",
    width: "80px",
    selector: (row) => row?.currencySymbol,
  },
  {
    name: "Amount",
    width: "150px",
    cell: (record) => {
      return (<CurrencyFormat displayType={'text'} thousandSeparator={true} value={truncateDecimals(record?.amount, 2)} />)
    },
  },
  {
    name: "User Id",
    width: "100px",
    selector: (row) => row.userCode,
  },
  {
    name: "Type",
    width: "150px",
    selector: (row) => row.paymentType,
  },
  {
    name: "Status",
    selector: (row) => row.status,
    width: "150px",
  },
  {
    name: "Reason",
    width: "300px",
    selector: (row) => !isEmpty(row.reason) ? row.reason : "-",
  },
];

const TransactionHistory = () => {

  // state
  const [data, setData] = useState([]);
  const [count, setCount] = useState(0);
  const [pageDoc, setPageDoc] = useState({ page: 1, limit: 20 });
  const { page, limit } = pageDoc;


  //function
  const getData = async () => {
    try {
      const { status, result } = await getTrnxHistory({ page, limit });
      if (status === "success") {
        setData(result.data);
        setCount(result.count);
      }
    } catch (err) { }
  };

  useEffect(() => {
    getData();
  }, [page, limit]);

  return (
    <>
      {/* <div className="inner_title_wrapper">
        <h2 className="inner_title">Latest Transaction</h2>
      </div> */}
      <div className="dash_box">
        <div className="primary_datatable primary_datatable_chg">
          <DataTable
           columns={columns}
           data={data}
           pagination
           paginationServer
           paginationTotalRows={count}
           paginationPerPage={limit}
           paginationRowsPerPageOptions={[20, 30, 40, 50]}
           onChangePage={(page) =>
             setPageDoc({ ...pageDoc, ...{ page: page } })
           }
           onChangeRowsPerPage={(limit) =>
             setPageDoc({ ...pageDoc, ...{ page: 1, limit: limit } })
           }
          />
        </div>
      </div>
    </>
  );
};

export default TransactionHistory;

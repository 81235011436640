// import constant
import {
    SITE_SETTING,
} from '../constant';

const initialState = {
};

const siteSetting = (state = initialState, action) => {
    switch (action.type) {
        case SITE_SETTING:
            return {
                ...state,
                ...action.data
            };
        default:
            return state;
    }

}

export default siteSetting;
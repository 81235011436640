import React, { useEffect } from "react";
import Footer from "../components/Footer.js";
import Navbar from "../components/Navbar.js";
import NavbarFixed from "../components/Navbar-Fixed.js";

// components
import RegisterForm from "../components/Register";

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function Register() {
  return (
    <div>
      <ScrollToTopOnMount />
      <Navbar />
      <div className="innerpages_wrapper">
        <div className="auth_wrapper">
          <RegisterForm />
        </div>
      </div>
      <Footer />
      {/* <NavbarFixed /> */}
    </div>
  );
}

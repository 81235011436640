import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import PhoneInput from 'react-phone-input-2'
import "react-phone-input-2/lib/style.css";
import { isValidPhoneNumber } from "react-phone-number-input";
// api
import { createUser } from "../../api/users";

// lib
import { toastAlert } from "../../lib/toastAlert";
import isEmpty from "../../lib/isEmpty";
import { error } from "jquery";


const initialFormValue = {
  firstName: "",
  lastName: "",
  email: "",
  password: "",
  confirmPassword: "",
  referralCode: "",
  isTerms: "",
  otpStatus: false,
  otp: "",
  phoneNo: "",
  phoneCode: "",
  passwordIcon: false,
  confirmPasswordIcon: false,

};

const RegisterForm = () => {


  //hooks
  const navigate = useNavigate();
  // const location = useLocation();
  // const queryParams = new URLSearchParams(location.search);

  // state
  const [formValue, setFormValue] = useState(initialFormValue);
  const [loader, setLoader] = useState(false)
  const [errors, setErrors] = useState({});
  const [otpTextBox, setOtpTextBox] = useState(false);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const {
    email,
    password,
    confirmPassword,
    referralCode,
    isTerms,
    otpStatus,
    otp,
    phoneNo,
    phoneCode,
    passwordIcon,
    confirmPasswordIcon,
    firstName,
    lastName
  } = formValue;

  //function
  const handleChange = (e) => {
    const { name, value, checked } = e.target;
    if (name == "otp") {
      if (/[^0-9]/.test(value)) return;
    }
    setFormValue({
      ...formValue,
      ...{ [name]: name != "isTerms" ? value : checked },
    });
    if (!isEmpty(errors)) {
      setErrors({});
    }
  };

  const handlePhoneNumber = (value, country) => {
    const { dialCode } = country;
    let phoneNo = value;
    let formData = formValue;
    if (!isValidPhoneNumber('+' + value)) {
      setErrors({ ...errors, ...{ 'phoneNo': 'Invalid mobile number' } });
    } else {
      setErrors({ ...errors, ...{ 'phoneNo': '' } });
    }
    if (isEmpty(value)) {
      setErrors({ ...errors, ...{ 'phoneNo': '' } });
    }
    if (dialCode) {
      formData = {
        ...formData, ...{
          phoneCode: dialCode,
          phoneNo: phoneNo.slice(dialCode.length),
        }
      }
    } else if (value) {
      formData = { ...formData, ...{ phoneNo } }
    }
    setFormValue(formData)
  }

  const handlePassIcon = (name) => setFormValue({ ...formValue, ... { [name]: !formValue[name] } })


  const handleSubmit = async (e) => {
    try {
      setLoader(true)
      const { status, loading, message, error } = await createUser(formValue);
      setLoader(loading)
      if (status == "success") {
        setFormValue(initialFormValue);
        toastAlert("success", message, "signup", "TOP_CENTER");
        navigate("/login");
      } else if (status === "OTP") {
        setMinutes(1);
        setSeconds(59);
        setOtpTextBox(true)
        setFormValue({ ...formValue, ...{ otpStatus: true } });
        setErrors({});
        toastAlert("success", message, "signup", "TOP_CENTER");
      } else {
        if (error) {
          setErrors(error);
          return false;
        }
        toastAlert("error", message, "signup", "TOP_CENTER");
      }
    } catch (err) {
      console.log(err, 'error')
    }
  };

  useEffect(() => {
    let myInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(myInterval);
        } else {
          setMinutes(minutes - 1);
          setSeconds(59);
        }
      }
    }, 1000);
    if (otpStatus && seconds === 0 && minutes === 0) {
      setFormValue({ ...formValue, ... { 'otpStatus': false } })
    }
    return () => {
      clearInterval(myInterval);
    };
  }, [seconds, minutes]);

  // useEffect(() => {
  //   if (queryParams.get("ref")) {
  //     setFormValue({
  //       ...formValue,
  //       ...{ referralCode: queryParams.get("ref") },
  //     });
  //   }
  // }, [queryParams.get("ref")]);

  return (
    <div className="container">
      <div className="row">
        <div className="col-xl-10 m-auto">
          <div className="dash_box mb-0">
            <div className="row align-items-center">
              <div className="col-lg-6 text-center d-none d-lg-block">
                <img
                  src={require("../../assets/images/login_img.png")}
                  alt="Image"
                  className="img-fluid"
                />
              </div>
              <div className="col-lg-6">
                <div className="inner_title_wrapper mt-2">
                  <h2 className="inner_title">Register</h2>
                </div>
                <form className="auth_form">
                  <div class="mb-3">
                    <div className="row">
                      <div className="col-lg-6">
                        <label for="exampleFormControlInput1" class="form-label">
                          First Name
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          id="exampleFormControlInput1"
                          placeholder="Enter First Name"
                          name="firstName"
                          onChange={handleChange}
                          value={firstName}
                        />
                        <span className="text-danger">{errors?.firstName}</span>
                      </div>
                      <div className="col-lg-6">
                        <label for="exampleFormControlInput1" class="form-label">
                          Last Name
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          id="exampleFormControlInput1"
                          placeholder="Enter Last Name"
                          name="lastName"
                          onChange={handleChange}
                          value={lastName}
                        />
                        <span className="text-danger">{errors?.lastName}</span>
                      </div>
                    </div>
                  </div>
                  <div class="mb-3">
                    <label for="exampleFormControlInput1" class="form-label">
                      Email Address
                    </label>
                    <input
                      type="email"
                      class="form-control"
                      id="exampleFormControlInput1"
                      placeholder="Enter Email Address"
                      name="email"
                      onChange={handleChange}
                      value={email}
                    />
                    <span className="text-danger">{errors?.email}</span>
                  </div>
                  <div class="mb-3 phone_num">
                    <label for="inputPassword" class="form-label">
                      Phone Number
                    </label>
                    <PhoneInput
                      placeholder="Enter Phone Number"
                      value={phoneCode + phoneNo}
                      onChange={handlePhoneNumber}
                      specialLabel={false}
                      enableSearch={true}
                    />
                    <span className={"text-danger"}>
                      {errors && errors.phoneNo}
                    </span>
                  </div>

                  <div class="mb-3">
                    <label for="inputPassword" class="form-label">
                      Password
                    </label>
                    <div className="asset_deposit_grp">
                      <input
                        type={!passwordIcon ? "password" : "text"}
                        className="form-control primary_asset_inp"
                        id="inputPassword"
                        placeholder="Enter Password"
                        name="password"
                        onChange={handleChange}
                        value={password}
                      />
                      <span className="withdrawal_type">
                        <i class={passwordIcon ? "bi bi-eye-fill" : "bi bi-eye-slash-fill"} onClick={() => handlePassIcon('passwordIcon')}></i>
                      </span>
                    </div>
                    <span className="text-danger">{errors?.password}</span>

                  </div>
                  <div class="mb-3">
                    <label for="inputPassword" class="form-label">
                      Confirm Password
                    </label>
                    <div className="asset_deposit_grp">
                      <input
                        type={!confirmPasswordIcon ? "password" : "text"}
                        className="form-control primary_asset_inp"
                        id="inputPassword"
                        placeholder="Enter Confirm Password"
                        name="confirmPassword"
                        onChange={handleChange}
                        value={confirmPassword}
                      />
                      <span className="withdrawal_type">
                        <i class={confirmPasswordIcon ? "bi bi-eye-fill" : "bi bi-eye-slash-fill"} onClick={() => handlePassIcon('confirmPasswordIcon')}></i>
                      </span>
                    </div>
                    <span className="text-danger">
                      {errors?.confirmPassword}
                    </span>
                  </div>
                  {/* <div class="mb-3">
                    <label for="inputPassword" class="form-label">
                      Referral (Optional)
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      id="referral"
                      placeholder="Enter Referral ID"
                      name="referralCode"
                      onChange={handleChange}
                      value={referralCode}
                    />
                    <span className="text-danger">{errors?.referralCode}</span>
                  </div> */}
                  {otpStatus === true && (
                    <div class="mb-3">
                      {/* <label for="inputPassword" class="form-label">
                        OTP
                      </label> */}
                      <div className="d-flex align-items-center justify-content-between">
                        {
                          (minutes != 0 || seconds != 0) && otpTextBox ?
                            <p >
                              {" "}
                              OTP valid up to :{" "}
                              {`${minutes}:${seconds <= 9 ? `0${seconds}` : seconds}`}{" "} </p> : 'OTP'
                        }
                      </div>
                      <input
                        type="text"
                        class="form-control"
                        id="otp"
                        placeholder="Enter OTP"
                        name="otp"
                        onChange={handleChange}
                        value={otp}
                      />
                      <span className="text-danger">{errors?.otp}</span>
                    </div>
                  )}
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      id="inlineCheckbox1"
                      name="isTerms"
                      onChange={handleChange}
                      checked={isTerms}
                    />
                    <label class="form-check-label" for="inlineCheckbox1">
                      I Understand <Link to="/terms">Terms of Use</Link> and{" "}
                      <Link to="/privacy">Privacy Policy</Link>
                    </label>
                  </div>
                  <span className="text-danger">{errors?.isTerms}</span>
                  <br />
                  <div className="text-center mt-4 mb-4">
                    <button
                      type="button"
                      class="w-100 primary_btn"
                      onClick={handleSubmit}
                    >
                      {loader && <i class="fas fa-spinner fa-spin"></i>}
                      Register
                    </button>
                  </div>
                  <p class="form_note text-center mt-3">
                    Already have an account? <Link to="/login">Sign In</Link>
                  </p>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegisterForm;

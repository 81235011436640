import isEmpty from "../../lib/isEmpty";
export const DepositValidation = value => {
    let errors = {};
    let imageFormat = /\.(jpg|JPG|jpeg|JPEG|png|PNG|pdf)$/;

    if (isEmpty(value.amount)) {
        errors.amount = "Amount field is required"
    } else if (isNaN(value.amount)) {
        errors.amount = "Only allowed numeric"
    } else if (parseFloat(value.minimumDeposit) > parseFloat(value.amount)) {
        errors.amount = "Please Enter The Deposit Amount is Greater than or equal to " + value.minimumDeposit
    }


    // if (value.image && value.image.size) {
    //     if (value.image.size > 1000000) {
    //         errors.image = "Too large"
    //     } else if (!imageFormat.test(value.image.name)) {
    //         errors.image = "Please select valid format"
    //     }
    // } else {
    //     errors.image = "Image field is required";
    // }

    return errors;
}

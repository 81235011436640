import React, { useEffect } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import axios, { setAuthorization, removeAuthorization, handleResp } from '../config/axios'
// import api
import { emailVerify, resetPasswordVerification, loginWithurl, forceLogout } from '../api/users';
import { coinRequestVerify } from '../api/walletAction';

// import lib
import { toastAlert } from '../lib/toastAlert';
import { removeAuthToken, setAuthToken } from '../lib/localStorage';
import { useDispatch, useSelector } from "react-redux";
const EmailVerification = () => {

	const { authToken } = useParams();
	const navigate = useNavigate();
	const location = useLocation();
	const dispatch = useDispatch()
	// redux-state
	const { isAuth } = useSelector(state => state.auth);

	const emailActivation = async () => {
		try {
			const { status, message } = await emailVerify({ userId: authToken });
			toastAlert(status === 'success' ? status : 'error', message, 'emailVerify')
			navigate('/login')
		} catch (err) { }
	}
	const getloginToken = async () => {
		try {
			// let token = localStorage.getItem('user_token');
			// if (token) forceLogout(navigate, dispatch)

			await new Promise((resolve) => setTimeout(resolve, 1000));
			const { status, message } = await loginWithurl({ userId: authToken }, dispatch);
			toastAlert(status === 'success' ? status : 'error', message, 'emailVerify')
			navigate('/wallet')
		} catch (err) {
			console.log("getloginToken_err", err)
		}
	}

	const ResetPassword = async () => {
		try {
			const { status, message } = await resetPasswordVerification({
				authToken: authToken,
			});
			console.log(status, 'responseresponse', message)
			if (status == 'success') {
				toastAlert("success", message, "resetPassword");
				navigate("/reset-password/" + authToken);
			} else {
				toastAlert("error", message, "resetPassword");
				navigate("/login");
			}
		} catch (error) {
			console.log(error, "errro")
		}
	};

	const acceptCoinRequest = async () => {
		try {
			const { status, message } = await coinRequestVerify({ authToken });
			toastAlert(status === 'success' ? status : 'error', message, 'coinRequestVerify')
			navigate('/')
		} catch (err) { }
	}

	useEffect(() => {
		let pathname = location.pathname;

		if (pathname == '/verification/forgotPassword/' + authToken) {
			ResetPassword()
		}
		if (pathname == '/url-login/' + authToken) {
			let bear = `Bearer ${authToken}`
			// setAuthorization(bear)
			// setAuthToken(bear)
			// navigate('/')
			getloginToken()
		}
		// if (pathname == "/email-verification/" + authToken) {
		// 	emailActivation();
		// } else if (pathname == "/withdraw-verification/" + authToken) {
		// 	acceptCoinRequest();
		// }
	}, [])

	return (
		<h3>Loading...</h3>
	)
}

export default EmailVerification;
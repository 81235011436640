import React, { useState, useEffect } from "react";
import Pagination from "react-bootstrap/Pagination";
//import api
import { getBlogList } from "../../api/blog";

const BlogList = () => {
  //statte
  const [loader, setLoading] = useState(true);
  const [list, setList] = useState([]);
  const [filter, setFilter] = useState([]);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);

  const handlePageChange = async (pageChange) => {
    try {
      setLoading(true);
      const fillterObj = {};
      const sortObj = {};
      let reqData = {
        fillter: fillterObj,
        sortObj: sortObj,
        page: parseInt(pageChange),
        limit: parseInt(12),
      };
      setPage(pageChange);
      const { status, result } = await getBlogList(reqData);
      setLoading(false);
      if (status === "success") {
        console.log(result, "result");
        setList(result?.data ?? []);
        setCount(result?.count ?? 0);
      }
    } catch (err) {
      setLoading(false);
    }
  };
  const getBlogs = async () => {
    try {
      setLoading(true);
      const fillterObj = {};
      const sortObj = {};
      let reqData = {
        fillter: fillterObj,
        sortObj: sortObj,
        page: parseInt(1),
        limit: parseInt(12),
      };
      const { status, result } = await getBlogList(reqData);
      setLoading(false);
      if (status === "success") {
        console.log(result, "result");
        setList(result?.data ?? []);
        setCount(result?.count ?? 0);
      }
    } catch (err) {
      setLoading(false);
    }
  };

  useEffect(() => {
    getBlogs();
  }, []);

  const DynamicPagination = ({ totalPageCount, currentPage, onPageChange }) => {
    const paginationItems = [];

    // Previous Page
    paginationItems.push(
      <Pagination.Prev
        key="prev"
        onClick={() => onPageChange(currentPage - 1)}
        disabled={currentPage === 1}
      />
    );

    // Pages
    if (totalPageCount <= 5) {
      for (let i = 1; i <= totalPageCount; i++) {
        paginationItems.push(
          <Pagination.Item
            key={i}
            active={i === currentPage}
            onClick={() => onPageChange(i)}
          >
            {i}
          </Pagination.Item>
        );
      }
    } else {
      // Calculate page range
      let startPage = Math.max(1, currentPage - 2);
      let endPage = Math.min(totalPageCount, currentPage + 2);

      if (currentPage <= 3) {
        endPage = 5;
      } else if (currentPage >= totalPageCount - 2) {
        startPage = totalPageCount - 4;
      }

      // Add first page
      paginationItems.push(
        <Pagination.Item
          key={1}
          active={currentPage === 1}
          onClick={() => onPageChange(1)}
        >
          {1}
        </Pagination.Item>
      );

      // Add ellipsis if needed
      if (startPage > 2) {
        paginationItems.push(<Pagination.Ellipsis key="start-ellipsis" />);
      }

      // Add pages within range
      for (let i = startPage + 1; i < endPage; i++) {
        paginationItems.push(
          <Pagination.Item
            key={i}
            active={i === currentPage}
            onClick={() => onPageChange(i)}
          >
            {i}
          </Pagination.Item>
        );
      }

      // Add ellipsis if needed
      if (endPage < totalPageCount - 1) {
        paginationItems.push(<Pagination.Ellipsis key="end-ellipsis" />);
      }

      // Add last page
      paginationItems.push(
        <Pagination.Item
          key={totalPageCount}
          active={currentPage === totalPageCount}
          onClick={() => onPageChange(totalPageCount)}
        >
          {totalPageCount}
        </Pagination.Item>
      );
    }

    // Next Page
    paginationItems.push(
      <Pagination.Next
        key="next"
        onClick={() => onPageChange(currentPage + 1)}
        disabled={currentPage === totalPageCount}
      />
    );

    return <Pagination className="custom_pagination">{paginationItems}</Pagination>;
  };

  const [played, setPlayed] = useState(false);

  const handlePlay = () => {
    setPlayed(true);
  };

  return (
    <>
      {/* <div className="staking_top_flex">
                <ul
                    class="nav nav-pills custom_nav_tabs"
                    id="pills-tab"
                    role="tablist"
                >
                    <li class="nav-item" role="presentation">
                        <button
                            class={`nav-link ${tab == 'flexible' && 'active'}`}
                            id="pills-flexible-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#pills-flexible"
                            type="button"
                            role="tab"
                            aria-controls="pills-flexible"
                            aria-selected="true"
                            onClick={() => handleSubmit("flexible")}
                        >
                            Flexible
                        </button>
                    </li>
                    <li class="nav-item" role="presentation">
                        <button
                            class={`nav-link ${tab == 'fixed' && 'active'}`}
                            id="pills-fixed-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#pills-fixed"
                            type="button"
                            role="tab"
                            aria-controls="pills-fixed"
                            aria-selected="false"
                            onClick={() => handleSubmit("fixed")}
                        >
                            Fixed
                        </button>
                    </li>

                </ul>
                <div className="search_grp">
                    <input
                        type="text"
                        placeholder="Search"
                        onChange={handleSearch}
                        className="form-control"
                    />
                    <img
                        src={require("../../assets/images/search_icon.png")}
                        alt="Search"
                        className="img-fluid search_icon"
                    />
                </div>

            </div> */}

      <div class="tab-content" id="pills-tabContent">
        <div
          class="tab-pane fade show active"
          id="pills-flexible"
          role="tabpanel"
          aria-labelledby="pills-flexible-tab"
        >
          <div className="row">
            {!loader &&
              list &&
              list.length > 0 &&
              list.map((item, i) => {
                const videoExtensions = [
                  "mp4",
                  "avi",
                  "mov",
                  "mkv",
                  "wmv",
                  "webm",
                ]; // Add more video extensions if needed
                const ext = item?.image.split(".").pop().toLowerCase();
                let isImage = true;
                if (videoExtensions.includes(ext)) {
                  isImage = false;
                }
                if (filter) {
                  return (
                    <div className="col-md-6 col-lg-4 col-xl-3 d-flex">
                      <div className="dash_box dash_box_right w-100">
                        <div className="dash_box_right_top flex-dashbox-top justify-content-center">
                          <div className="crypto-img-name">
                            <h4 className="blog_title">{item.tittle}</h4>
                          </div>
                        </div>
                        <div className="dash_box_right_bottom">
                          <div className="img_box" >
                            {isImage ? (
                              <img
                                src={item.imageUrl}
                                alt="Crypto"
                                className="img-fluid"
                                loading="lazy"
                              />
                            ) : (
                              <video
                                className="img-fluid"
                                preload="auto"
                                controls
                                autoplay={played}
                                loop
                                onPlay={handlePlay}
                              >
                                <source src={item.imageUrl} type="video/mp4" />
                                Your browser does not support the video tag.
                              </video>
                            )}
                          </div>
                          <div className="blog_content">
                            <p className="mb-0"> <span dangerouslySetInnerHTML={{ __html: item?.description }} /></p>
                          </div>
                        </div>
                        <div className="button_grp justify-content-center">
                          <button
                            className="primary_btn primary_btn_transform"
                            disabled={true}
                          >
                            {item.viewButton}
                          </button>
                        </div>
                      </div>
                    </div>
                  );
                }
              })}
            {!loader && list && list.length == 0 && (
              <span style={{ textAlign: "center", display: "inline-block" }}>
                There are no live events to display
              </span>
            )}
            {loader && (
              <span style={{ textAlign: "center", display: "inline-block" }}>
                Loading...
              </span>
            )}
            {!loader && list && count > 12 && (
              <DynamicPagination
                totalPageCount={Math.ceil(count / 12)} // Example: Total number of pages
                currentPage={page} // Example: Current page number
                onPageChange={handlePageChange} // Example: Function to handle page change
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default BlogList;

import React, { useEffect } from "react";

// import components
import Footer from "../components/Footer";
import NavbarInner from "../components/Navbar-Inner";
import NavbarFixed from "../components/Navbar-Fixed";
import OrderHistory from "../components/Staking/OrderHistory";
import StakingHistory from "../components/Staking/StakingHistory";
import RedemptionHistory from "../components/Staking/RedemptionHistory";
import StackedDetail from "../components/Staking/StackedDetail";
// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function StakingHistoryPage(props) {
  return (
    <div>
      <ScrollToTopOnMount />
      <NavbarInner />
      <div className="innerpages_wrapper">
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <StackedDetail />
              <OrderHistory />
              <StakingHistory />
              <RedemptionHistory />
            </div>
          </div>
        </div>
      </div>
      <Footer />
      {/* <NavbarFixed /> */}
    </div>
  );
}

import React, { useEffect } from 'react';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';

const SecondChart = (props) => {
  const { piechart, piecurrency } = props;

  useEffect(() => {
    // Enable amCharts themes
    am4core.useTheme(am4themes_animated);

    // Set up license
    am4core.addLicense("CH123456789");

    // Create chart instance
    const chart = am4core.create("chartdiv", am4charts.PieChart3D);
    chart.hiddenState.properties.opacity = 0; // this creates initial fade-in

    chart.legend = new am4charts.Legend();
    chart.legend.maxHeight = 150;
    chart.legend.scrollable = true;

    const data =
      piecurrency &&
      piecurrency.length > 0 &&
      piecurrency.map((currency, index) => ({
        piecurrency: currency,
        piechart: piechart[index],
        color: index % 2 === 0 ? "#FF5733" : "#36A2EB" // Example alternating colors
      }));

    // Set data
    chart.data = data;

    // Create series
    const series = chart.series.push(new am4charts.PieSeries3D());
    series.dataFields.value = "piechart";
    series.dataFields.category = "piecurrency";

    // Configure label appearance
    series.labels.template.fill = am4core.color("#FFFFFF"); // Label text color
    series.labels.template.fontSize = 12; // Label font size
    series.labels.template.fontWeight = "bold"; // Label font weight
    series.ticks.template.disabled = true;
    series.labels.template.disabled = true;

    // Add custom colors to labels
    //  series.labels.template.adapter.add("fill", function(fill, target) {
    //   if (target.dataItem && target.dataItem.dataContext.piecurrency) {
    //     const currency = target.dataItem.dataContext.piecurrency;
    //     if (currency === "BTC") {
    //       return am4core.color("#FF0000"); // Red color for BTC
    //     } else if (currency === "ETH") {
    //       return am4core.color("#0000FF"); // Blue color for ETH
    //     }
    //   }
    //   return fill;
    // });

    // Customize legend labels and value labels
    chart.legend.labels.template.adapter.add("fill", function (fill, target) {
      if (target.dataItem && target.dataItem.dataContext.piecurrency) {
        return am4core.color("#36A2EB"); // Default white color for other currencies
      }
      return fill;
    });

    chart.legend.valueLabels.template.adapter.add("fill", function (fill, target) {
      if (target.dataItem && target.dataItem.dataContext.piecurrency) {
        return am4core.color("#36A2EB"); // Default white color for other currencies

      }
      return fill;
    });
    // Add custom colors to labels
    series.labels.template.adapter.add("fill", function (fill, target) {
      if (target.dataItem) {
        return target.dataItem.dataContext.color;
      }
      return fill;
    });

    // Clean up function for useEffect
    return () => {
      chart.dispose();
    };
  }, [piechart, piecurrency]); // Empty dependency array ensures this runs only once on mount

  return (
    <div id="chartdiv" style={{ width: "100%", height: "500px" }}></div>
  );
};

export default SecondChart;

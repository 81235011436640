// import package
import jwt_decode from "jwt-decode";

// import constant
import {
    SET_AUTHENTICATION,
    REMOVE_AUTHENTICATION
} from '../constant';

// import config
import { createSocketUser } from '../config/socketConnectivity';
import { removeAuthorization } from '../config/axios'
import { removeAuthToken } from '../lib/localStorage'

// import lib
import isEmpty from "../lib/isEmpty";

export const decodeJwt = (token, dispatch) => {
    try {

        if (!isEmpty(token)) {
            token = token.replace('Bearer ', '')
            const decoded = jwt_decode(token);
            if (decoded) {
                createSocketUser(decoded?.data?._id)
                dispatch({
                    type: SET_AUTHENTICATION,
                    authData: {
                        isAuth: true,
                        userId: decoded?.data?._id,
                        uniqueId: decoded?.data?.uniqueId
                    }
                })
            }
        }
    } catch (err) {
        console.log(err, 'errror');
        // removeAuthToken()
        // removeAuthorization() 
        // dispatch({
        //     type: REMOVE_AUTHENTICATION,
        //     authData: {
        //         isAuth: false,
        //     }
        // })
    }
}

export const tokenVerify = (token) => {
    try {
        if (!isEmpty(token)) {
            token = token.replace('Bearer ', '')
            const decoded = jwt_decode(token);
            if (decoded) {
                return true
            }
        }
        return false
    } catch (err) {
        return false
    }
}
// import constant
import { SET_AVAILABLE_BALANCE } from '../constant';

const initialValue = {
}
const availableBal = (state = initialValue, action) => {
    switch (action.type) {
        case SET_AVAILABLE_BALANCE:
            return {
                ...state,
                ...action.data
            };
        default:
            return state;
    }
}

export default availableBal;
import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route, useRoutes } from "react-router-dom";
import { ToastContainer } from "react-toastify";


// import config
import { socket } from './config/socketConnectivity';

// import Context
import SocketContext from './context/SocketContext';

// helper route
import HelperRoute from './components/Route/HelperRoute';
import routes from './routes';

// import redux
import { Provider } from "react-redux";
import { PersistGate } from 'redux-persist/integration/react';
import store, { persistor } from './store';

// import api
import { decodeJwt } from './api/jsonWebToken';

// import lib
import isLogin from './lib/isLogin';
import { getAuthToken } from './lib/localStorage';

const AppRoutes = () => {
  return useRoutes(routes);
};

const App = () => {

  const { isAuth } = store.getState().auth;

  // console.log(isAuth,'isAuthisAuth')

  useEffect(() => {
    if (isAuth != true && isLogin()) {
      decodeJwt(getAuthToken(), store.dispatch)
    }
  }, [])

  return (
    <Provider store={store}>
      {/* <PersistGate loading={null} persistor={persistor}> */}
      <BrowserRouter basename="/">
        <SocketContext.Provider value={{ socket }}>
          <HelperRoute />
          <ToastContainer />
          <AppRoutes />
        </SocketContext.Provider>
      </BrowserRouter>
      {/* </PersistGate> */}
    </Provider>
  );
};

export default App;